<template lang="pug">
.season-card(:class="season.name" @click="onSeeAll")
  img(:src="season.img")
</template>

<script>
  export default {
    props: {
      season: {
        type: Object,
        default: () => {},
      },
      name: { type: String, required: true },
      to: { type: [String, Object] },
      needToTrack: { type: Boolean, default: true },
    },
    data() {
      return {
        loaded: false,
      };
    },
    computed: {
      background() {
        return {
          'background-image': `url(${this.season.img})`,
        };
      },
    },
    methods: {
      onSeeAll() {
        if (this.needToTrack) {
          // todo
          this.$emit('track', { setting: 'theme_open', options: this.name });
        }
        this.$emit('navigateWithFilterReset', this.to);
      },
    },
  };
</script>

<style lang="sass" scoped>
  .season-card
    position: relative
    background-repeat: no-repeat
    background-size: cover
    background-position: left top
    border-radius: 1rem
    aspect-ratio: 583 / 353
    border: 1px solid #E4E3E5
    overflow: hidden
    cursor: pointer
    img
      object-fit: cover
      width: 100%
      height: auto
      transition: all .3s
    @media (pointer: fine) and (hover: hover)
      &:hover
        img
          transform: scale(1.06)
</style>
